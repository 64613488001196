.App {
  text-align: center;
  transition: all .9s;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-text{
  /* border: 2px solid white; */
  position: relative;
  transition: all .9s;
}

h1.login-text::before{
  content: "";
  position: absolute;
  height: 0;
  top: 0;
  /* z-index: -1; */
  border-left: 2px solid green;
  /* border-right: 2px solid rgb(2, 29, 2); */
  transition: all .7s;
}
h1.login-text::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 0;
  z-index: 1;
  border-right: 2px solid rgb(2, 29, 2);
  transition: all .5s;
}
h1.login-text:hover::before{
  height: 100%;
}
h1.login-text:hover::after{
  height: 100%;
}

.top::before{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  border-top: 2px solid red;
  transition: all .9s;
  z-index: 11;
}
.bottom::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 0%;
  border-top: 2px solid red;
  transition: all .5s;
  z-index: 100;
}
.login-text:hover .bottom::after,.login-text:hover .top::before{
  width: 100%;
}
.top{
  padding: 10px 20px;
}
/* .top:hover::before{
  width: 100%;
}
.bottom:hover:after{
  width: 100%;
} */
